import type { CbidFormatted, } from '@/components/types/CbidFormatted.d'
import type { Cbid, } from '@/components/types/report/Cbid.d'
import type { LanCbidFeedback, CbidFeedback, } from '@/components/types/report/DeviceFeedback.d'
import type { LanCbidTest, } from '@/components/types/report/LanCbidTest.d'

import useReportStore from '@/stores/report'

export const TESTS_LIST = ['is_online', 'remote_orders_ok']

function isReportExist (): boolean {
    const reportStore = useReportStore()
    if (reportStore.report && reportStore.report.report && reportStore.report !== null && reportStore.report.report !== null)
        return true

    return false
}

export function getReportId (): number {
    const reportStore = useReportStore()
    const isReportExisted = isReportExist()
    if (isReportExisted === false || isReportExist === null)
        return 0

    return reportStore.report?.report.id
}

export function getLanCbidFeedbacks (): LanCbidFeedback | null {
    const reportStore = useReportStore()
    if (
        !isReportExist()
        || !reportStore.report?.report.lan_device_feedbacks
    )
        return null

    const lanCbidFeedbacks = reportStore.report.report.lan_device_feedbacks
    return lanCbidFeedbacks
}

export function getCbidFeedbackByCbid (
    lanCbidFeedbacks: LanCbidFeedback,
    cbid: Cbid
): CbidFeedback {
    if (lanCbidFeedbacks === null || !Object.prototype.hasOwnProperty.call(lanCbidFeedbacks, cbid.chargeboxid)) {
        return {
            is_valid: false,
            comment: '',
            chargebox_id: cbid.chargeboxid,
        }
    }
    const cbidFeedback = lanCbidFeedbacks[cbid.chargeboxid]
    return cbidFeedback
}

export function getLanCbidTests (): LanCbidTest | null {
    const reportStore = useReportStore()
    if (
        !isReportExist()
        || !reportStore.report.report.lanDeviceTests
    )
        return null

    const lanCbidTests = reportStore.report.report.lanDeviceTests
    return lanCbidTests
}

function getDefaultTests (cbid: Cbid): LanCbidTest[] {
    return TESTS_LIST.map((testName: string) => ({
        lan_device_chargeboxid: cbid.chargeboxid,
        station_check_report_id: getReportId(),
        is_ok: false,
        test_name: testName,
        is_loading: false,
        is_already_tested: false,
    }))
}

export function getCbidTestsByCbid (
    lanCbidTests: LanCbidTest,
    cbid: Cbid
): LanCbidTest[] {
    if (lanCbidTests === null)
        return getDefaultTests(cbid)

    const cbidTests = lanCbidTests.filter((lanCbidTest: LanCbidTest) => lanCbidTest.lan_device_chargeboxid === cbid.chargeboxid)
    if (cbidTests.length === 0)
        return getDefaultTests(cbid)

    return cbidTests.map((cbidTest: LanCbidTest) => ({
        ...cbidTest,
        is_loading: false,
        is_already_tested: true,
    }))
}

export function getCbidsFormatted (): CbidFormatted {
    const reportStore = useReportStore()
    const cbids = reportStore.report.devices
    const lanCbidFeedbacks = getLanCbidFeedbacks()
    const lanCbidTests = getLanCbidTests()

    const cbidsFormatted = cbids.map((cbid: Cbid) => {
        const cbidFeedback = getCbidFeedbackByCbid(lanCbidFeedbacks, cbid)
        const cbidTests = getCbidTestsByCbid(lanCbidTests, cbid)
        return {
            cbid,
            feedback: cbidFeedback,
            errorDuringFeedback: false,
            tests: cbidTests,
        }
    })
    return cbidsFormatted
}

export function testsAreValidatedForCbid (
    cbidFormatted :CbidFormatted
): boolean {
    let isTestsAreValidated = true
    cbidFormatted.tests.forEach((test: LanCbidTest) => {
        if (!test.is_ok)
            isTestsAreValidated = false
    })
    return isTestsAreValidated
}
