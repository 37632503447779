<template>
    <div id="app">
        <div
            v-if="isMobile && route.meta?.hasSidebar"
            class="is-flex is-align-items-center p-3 has-background-white cursor-pointer"
            @click="isOpenSidebar = true"
        >
            <o-icon
                icon="bars"
                variant="primary"
                size="medium"
            />
        </div>
        <side-bar
            v-if="isDisplaySidebar"
            @sidebarExtendedClose="handleSidebarExtendedClose()"
            :isMobile="isMobile"
        />
        <banner-header />
        <modal-reset-install
            :isModalActive="userStore.isModalResetInstallActive"
            :canCancelIsOnlyButton="true"
        />
        <div
            :class="isDisplaySidebar ? 'margin-left-for-sidebar' : ''"
        >
            <router-view v-slot="{ Component }">
                <component :is="Component" />
            </router-view>
        </div>

        <div
            v-if="showScrollArrow"
            class="scroll-arrow"
            @click="scrollToBottom"
        >
            <o-icon
                icon="arrow-down"
                variant="primary"
                size="medium"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import {
    onMounted, ref, computed, onUnmounted, watch,
} from 'vue'
import { useRecaptchaProvider, } from 'vue-recaptcha'
import { useRoute, RouterView, } from 'vue-router'

import ModalResetInstall from '@/components/modals/ModalResetInstall.vue'
import SideBar from '@/components/sidebar/SideBar.vue'
import BannerHeader from '@/components/ui/BannerHeader.vue'

import steps from '@/constants/steps'
import useDashboardStore from '@/stores/dashboard'
import useReportStore from '@/stores/report'
import useStepsStore from '@/stores/steps'
import useUserStore from '@/stores/User'

const stepsStore = useStepsStore()
const userStore = useUserStore()
const reportStore = useReportStore()
const dashboardStore = useDashboardStore()
const isOpenSidebar = ref(false)
const route = useRoute()
const isMobile = ref(window.innerWidth < 768)
const showScrollArrow = ref(false)
const showCaptcha = ref(true)

const handleResize = () => {
    isMobile.value = window.innerWidth < 768
}

const handleScroll = () => {
    // Si le contenu peut être défilé et que l'utilisateur n'est pas encore tout en bas
    if (
        document.documentElement.scrollHeight > window.innerHeight
        && window.scrollY < (document.documentElement.scrollHeight - window.innerHeight - 10)
        && !showCaptcha.value
    ) showScrollArrow.value = true
    else showScrollArrow.value = false
}

onMounted(() => {
    stepsStore.setSteps(steps)

    window.addEventListener('resize', handleResize)
    window.addEventListener('scroll', handleScroll)
})

onUnmounted(() => {
    window.removeEventListener('resize', handleResize)
    window.removeEventListener('scroll', handleScroll)
})

useRecaptchaProvider()

const scrollToBottom = () => {
    window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: 'smooth',
    })
}

// Cache le captcha une fois que l'utilisateur est connecté
watch(
    () => route.name,
    () => {
    // On doit utiliser du javascript pur car la div du captcha est à l'extérieur de la div de l'app
        const captchaElement = document.querySelector('.grecaptcha-badge')
        if (route.meta.requiresAuth && captchaElement) {
            captchaElement.style.visibility = 'hidden'
            showCaptcha.value = false
        } else if (captchaElement) {
            captchaElement.style.visibility = 'visible'
            showCaptcha.value = true
        }
    },
    { immediate: true, } // Exécuter le gestionnaire immédiatement avec la valeur actuelle
)

const isDisplaySidebar = computed(() => {
    if (isMobile.value) return isOpenSidebar.value && route.meta?.hasSidebar

    return route.meta?.hasSidebar
})

reportStore.$subscribe(() => {
    if (reportStore.report !== null) dashboardStore.initialize()
})

const handleSidebarExtendedClose = () => {
    if (isMobile.value) isOpenSidebar.value = false
}

</script>

<style scoped lang="scss">
@import '@/assets/styles/variables.scss';

.scroll-arrow {
    position: fixed;
    bottom: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 2000;
    border-radius: 50%;
    outline: 2px solid $primary;
}
</style>
