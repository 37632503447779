import type { Step, } from '@/types/Step.d'

const steps: Step[] = [
    {
        id: 0,
        name: 'Authentification',
        isActive: true,
        isDisabledInWorkshopMode: true,
    },
    {
        id: 1,
        name: 'Station',
        isActive: false,
        isDisabledInWorkshopMode: false,
    },
    {
        id: 2,
        name: 'Vérification des bornes',
        isActive: false,
        isDisabledInWorkshopMode: true,
    },
    {
        id: 3,
        name: 'Test des bornes',
        isActive: false,
        isDisabledInWorkshopMode: false,
    }
]

export default steps
