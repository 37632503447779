import { useProgrammatic, } from '@oruga-ui/oruga-next'
import axios, { AxiosError, } from 'axios'
import { cloneDeep, each, } from 'lodash'
import type { App, } from 'vue'
import { computed, inject, } from 'vue'

import { resetAllStores, } from '@/services/storeService'
import useUserStore from '@/stores/User'

export function getBaseUrl () {
    return import.meta.env.VITE_ZEST_API_URL
}

export function getToken () {
    return useUserStore().getToken
}

// Gracefully handle laravel validation errors
const handleValidationErrors = (axiosError: Error) => {
    const { notification, } = useProgrammatic().oruga
    if (
        axiosError instanceof AxiosError
        && axiosError?.response?.status === 422
    ) {
        each(axiosError.response.data.errors, (error) => {
            each(error, () => {
                notification.open({
                    message: error,
                    variant: 'danger',
                })
            })
        })
    }
}

const handleAuthorizationError = (axiosError: Error) => {
    const { notification, } = useProgrammatic().oruga

    if (axiosError instanceof AxiosError && axiosError?.response?.status === 401) {
        notification.open({
            message: 'Vous n\'êtes pas autorisé à accéder à cette ressource.',
            variant: 'danger',
        })
        if (axiosError.response.status === 401) {
            resetAllStores()
            window.location.reload()
        }
    }
}

const handleGenericServerError = (axiosError: Error) => {
    const { notification, } = useProgrammatic().oruga
    if (
        axiosError instanceof AxiosError
        && axiosError?.response?.status === 500
    ) {
        notification.open({
            message: 'Une erreur est survenue, veuillez réessayer ultérieurement',
            variant: 'danger',
        })
    }
}

const authApi = axios.create({
    baseURL: getBaseUrl(),
})

authApi.interceptors.response.use(
    (response) => response,
    (axiosError: Error) => {
        handleGenericServerError(axiosError)
        handleValidationErrors(axiosError)
        return Promise.reject(axiosError)
    }
)

const loggedApi = axios.create({
    baseURL: getBaseUrl(),
    headers: {
        Authorization: null,
    },
})

loggedApi.interceptors.request.use(
    (config) => {
        const configWithAuth = cloneDeep(config)

        configWithAuth.headers.Authorization = `Bearer ${
            useUserStore().getToken
        }`
        return configWithAuth
    },
    (axiosError: Error) => {
        handleGenericServerError(axiosError)
        handleAuthorizationError(axiosError)
        handleValidationErrors(axiosError)
    }
)

// Used to make a global api error handler
loggedApi.interceptors.response.use(
    (response) => response,
    (axiosError) => {
        handleGenericServerError(axiosError)
        handleAuthorizationError(axiosError)
        handleValidationErrors(axiosError)

        return Promise.reject(axiosError)
    }
)

const install = (app: App) => {
    app.provide(
        '$api',
        computed(() => (useUserStore().getToken
            ? loggedApi
            : authApi))
    )
}

/* value must be extracted with the .value property to keep reactivity */
const useApi = () => inject(
    '$api',
    computed(() => authApi),
    false
)

export { useApi, }

export default install
