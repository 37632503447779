import { defineStore, } from 'pinia'
import { ref, computed, } from 'vue'

import type { Step, } from '@/types/Step.d'

export const STEP_CHECK_LOCATION = 1
export const STEP_VERIF = 2
export const STEP_TEST = 3
export const STEP_DISPLAY_BUTTON_ADD_COMMENT = 2

const useStepsStore = defineStore('steps', () => {
    const steps = ref<Step[]>([])
    const indexCurrentStep = ref<number>(0)
    const indexMaxStep = ref<number>(0)

    function setSteps (newSteps: Step[]) {
        steps.value = newSteps
    }

    function inactiveAllSteps () {
        steps.value = steps.value.map((step: Step) => ({
            ...step,
            isActive: false,
        }))
    }

    function setIndexMaxStep (newMaxStep: number) {
        indexMaxStep.value = newMaxStep
    }

    function setNewStep (indexNextStep: number) {
        inactiveAllSteps()
        steps.value[indexNextStep].isActive = true
        indexCurrentStep.value = indexNextStep

        if (indexNextStep > indexMaxStep.value)
            indexMaxStep.value = indexNextStep
    }

    function putStepVerif () {
        setNewStep(STEP_VERIF)
    }

    function putStepTest () {
        setNewStep(STEP_TEST)
    }

    const isStepVerif = computed(() => steps.value[indexCurrentStep.value].id === STEP_VERIF)

    const isDisplayButtonAddComment = computed(() => steps.value[indexCurrentStep.value].id >= STEP_DISPLAY_BUTTON_ADD_COMMENT)

    const currentStepId = computed(() => steps.value[indexCurrentStep.value].id)

    const $reset = () => {
        indexCurrentStep.value = 0
        indexMaxStep.value = 0
    }

    return {
        steps,
        currentStepId,
        indexCurrentStep,
        indexMaxStep,
        setSteps,
        setNewStep,
        putStepVerif,
        putStepTest,
        isStepVerif,
        isDisplayButtonAddComment,
        setIndexMaxStep,
        $reset,
    }
})

export default useStepsStore
