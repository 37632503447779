import { ROUTES_NAMES, } from '@/config/routesNames'

import useBannerStore from '@/stores/banner'
import useDashboardStore from '@/stores/dashboard'
import useReportStore from '@/stores/report'
import useStepsStore from '@/stores/steps'
import useUserStore from '@/stores/User'

export function resetAllStoresExpectedUser () {
    const dashboardStore = useDashboardStore()
    const reportStore = useReportStore()
    const bannerStore = useBannerStore()
    const stepsStore = useStepsStore()
    dashboardStore.$reset()
    reportStore.$reset()
    bannerStore.$reset()
    stepsStore.$reset()
}

export function resetAllStores () {
    const userStore = useUserStore()
    const dashboardStore = useDashboardStore()
    const reportStore = useReportStore()
    const bannerStore = useBannerStore()
    const stepsStore = useStepsStore()
    userStore.$reset()
    dashboardStore.$reset()
    reportStore.$reset()
    bannerStore.$reset()
    stepsStore.$reset()
}

export function resetAllStoresExceptUser () {
    const dashboardStore = useDashboardStore()
    const reportStore = useReportStore()
    const bannerStore = useBannerStore()
    const stepsStore = useStepsStore()
    dashboardStore.$reset()
    reportStore.$reset()
    bannerStore.$reset()
    stepsStore.$reset()
}

export function checkEmailAndToken (): boolean {
    const userStore = useUserStore()
    if (userStore.isWorkshop) return true
    return userStore.email !== null && userStore.token !== null
}

export function checkUserStore (): boolean {
    const userStore = useUserStore()
    if (userStore.isWorkshop) return true
    return userStore.email !== null
        && userStore.token !== null
        && userStore.company !== null
        && userStore.firstname !== null
        && userStore.lastname !== null
        && userStore.phone !== null
}

export function checkReportStore (): boolean {
    const reportStore = useReportStore()
    const userStore = useUserStore()
    if (userStore.isWorkshop) return true
    return reportStore.report !== null
        && reportStore.report.id !== null
}

export function checkStepId (stepId: number) {
    const stepsStore = useStepsStore()
    const userStore = useUserStore()
    if (userStore.isWorkshop) return true
    return stepsStore.indexCurrentStep === stepId
}

export function resetAllStoresAndDisplayError (
    router: any
) {
    resetAllStores()
    router.push({
        name: ROUTES_NAMES.home,
        query: { error: '1', },
    })
}
