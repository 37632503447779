import { useRouteQuery, } from '@vueuse/router'
import { storeToRefs, } from 'pinia'
import { createRouter, createWebHistory, } from 'vue-router'

import { ROUTES_NAMES, } from '@/config/routesNames'

import useStepsStore from '@/stores/steps'
import useUserStore from '@/stores/User'

export const routes = [
    {
        path: '/',
        name: ROUTES_NAMES.home,
        component: () => import('@/views/HomeView.vue'),
    },
    {
        path: '/informations',
        name: ROUTES_NAMES.informations,
        component: () => import('@/views/InformationsView.vue'),
    },
    {
        path: '/auth',
        name: ROUTES_NAMES.authentification,
        component: () => import('@/views/AuthView.vue'),
    },
    {
        path: '/email-sent',
        name: ROUTES_NAMES.emailSent,
        component: () => import('@/views/EmailSentView.vue'),
    },
    {
        path: '/test-mode-selection',
        name: ROUTES_NAMES.testModeSelection,
        component: () => import('@/views/TestModeSelectionView.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/complete-auth',
        name: ROUTES_NAMES.completeAuth,
        component: () => import('@/views/CompleteAuthView.vue'),
        meta: {
            requiresAuth: true,
            hasSidebar: true,
            stepId: 0,
        },
    },
    {
        path: '/search-station',
        name: ROUTES_NAMES.searchStation,
        component: () => import('@/views/SearchLocationView.vue'),
        meta: {
            requiresAuth: true,
            hasSidebar: true,
            stepId: 1,
        },
    },
    {
        path: '/check-location',
        name: ROUTES_NAMES.checkLocation,
        component: () => import('@/views/CheckLocationView.vue'),
        meta: {
            requiresAuth: true,
            hasSidebar: true,
            stepId: 1,
        },
    },
    {
        path: '/dashboard',
        name: ROUTES_NAMES.dashboard,
        children: [
            {
                path: 'step-informations',
                name: ROUTES_NAMES.stepInformations,
                component: () => import('../views/StepInformationsView.vue'),
                meta: {
                    requiresAuth: true,
                    hasSidebar: true,
                    stepId: 2,
                },
            },
            {
                path: 'step-tests',
                name: ROUTES_NAMES.stepTests,
                component: () => import('../views/StepTestsView.vue'),
                meta: {
                    requiresAuth: true,
                    hasSidebar: true,
                    stepId: 3,
                },
            }
        ],
    },
    {
        path: '/tool-improvement-form',
        name: ROUTES_NAMES.toolImprovementForm,
        component: () => import('../views/ToolImprovementFormView.vue'),
        meta: {
            requiresAuth: true,
            hasSidebar: true,
            stepId: 3,
        },
    },
    // always keep not found at the end
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: () => import('../views/404View.vue'),
    }
]

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes,
})

router.beforeEach((to, from) => {
    const userStore = useUserStore()
    const {
        token, isModalResetInstallActive, email, firstname,
    } = storeToRefs(userStore)
    const queryToken = useRouteQuery<string | null>('token', null, {
        route: to,
    })
    isModalResetInstallActive.value = false

    // If the user try to access a page that require auth, and he is not logged in, redirect him to the home page
    if (to.meta.requiresAuth && !token?.value)
        return { path: '/', }

    // If the user come from the email link with token in url, store it and redirect him to the test mode selection page
    if (queryToken.value) {
        token.value = queryToken.value
        return { path: '/test-mode-selection', }
    }

    // If the user arrives from an empty page (i.e. he directly accesses a url without going through the classic route),
    // We ask him if he wants to continue or reset his installation
    const urlsToExcludes = [ROUTES_NAMES.testModeSelection]
    if (from.name === undefined && to.name !== undefined
        && token.value !== null && email.value !== null && firstname.value !== null
        && !urlsToExcludes.includes(to.name)
    )
        isModalResetInstallActive.value = true

    if (to.meta?.hasSidebar && to.meta?.stepId !== undefined) {
        const { setNewStep, } = useStepsStore()
        setNewStep(to.meta.stepId as number)
    }

    return true
})

export default router
