export const ROUTES_NAMES = {
    home: 'home',
    informations: 'informations',
    authentification: 'authentification',
    emailSent: 'emailSent',
    testModeSelection: 'testModeSelection',
    completeAuth: 'completeAuth',
    searchStation: 'searchStation',
    checkLocation: 'checkLocation',
    stepInformations: 'stepInformations',
    stepTests: 'stepTests',
    dashboard: 'dashboard',
    toolImprovementForm: 'toolImprovementForm',
}
