<template>
    <div
        v-if="bannerStore.isActiveBanner"
        class="is-flex is-flex is-flex-direction-row banner-header is-align-items-center is-justify-content-center"
        :class="`has-background-${bannerStore.colorBanner}`"
    >
        <template v-if="bannerStore.isBannerModeWorkshop">
            Vous êtes actuellement en mode atelier. Pour effectuer des mises en service réelles, passez en&nbsp;
            <span
                class="text-workshop"
                @click="bannerStore.isBannerModeWorkshopClick = true"
            >
                mode installateur
            </span>.
        </template>
        <template v-else>
            {{ bannerStore.textBanner }}
        </template>
    </div>
</template>

<script setup lang="ts">
import useBannerStore from '@/stores/banner'

const bannerStore = useBannerStore()
</script>

<style scoped lang="scss">
@import '@/assets/styles/variables.scss';

.banner-header{
    color: white;
    font-size: 16px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.text-workshop {
    cursor: pointer;
    text-decoration: underline;
}
</style>
