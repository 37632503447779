import { defineStore, } from 'pinia'
import { computed, ref, } from 'vue'

const useUserStore = defineStore('user', () => {
    const firstname = ref<string | null>(null)
    const lastname = ref<string | null>(null)
    const phone = ref<string | null>(null)
    const company = ref<string | null>(null)
    const email = ref<string | null>(null)
    const captcha = ref<string | null>(null)
    const token = ref<string | null>(null)
    const isWorkshop = ref<boolean>(false)
    const isHelpActive = ref<boolean>(true)
    const evseRef = ref<string | null>(null)
    const isModalResetInstallActive = ref<boolean>(false)

    const getAuthInfo = computed(() => ({
        email: email.value,
        captcha: captcha.value,
        token: token.value,
    }))

    const getUserInfo = computed(() => ({
        firstname: firstname.value,
        name: lastname.value,
        phone: phone.value,
        company: company.value,
        email: email.value,
    }))

    const getToken = computed(() => token.value)

    const getTestMode = computed(() => isWorkshop.value)

    const $reset = () => {
        firstname.value = null
        lastname.value = null
        phone.value = null
        company.value = null
        email.value = null
        captcha.value = null
        token.value = null
        evseRef.value = null
        isWorkshop.value = false
    }

    return {
        firstname,
        lastname,
        phone,
        company,
        email,
        captcha,
        token,
        isWorkshop,
        isHelpActive,
        evseRef,
        getAuthInfo,
        getUserInfo,
        getToken,
        getTestMode,
        isModalResetInstallActive,
        $reset,
    }
})

export default useUserStore
