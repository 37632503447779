import { getReportId, } from '@/services/cbidService'
import useUserStore from '@/stores/User'

export async function putFeedbackCbid (
    api: any,
    chargeboxid: string,
    isValid: boolean,
    comment: string
) {
    const reportId = getReportId()
    const url = `/reports/${reportId}/lan-devices/${chargeboxid}/feedback`

    const data = {
        is_valid: isValid
            ? 1
            : 0,
        comment,
    }

    return api.put(url, data)
}

export function sendTestCbids (api: any, cbids: string[], tests: string[]) {
    const userStore = useUserStore()
    const url = '/lan-device-test/launch'

    type PostDataType = {
        is_installer_test_course: boolean
        cbids: string[]
        tests: string[]
    }

    const postData: PostDataType = {
        is_installer_test_course: userStore.isWorkshop,
        cbids,
        tests,
    }

    return api.post(url, postData)
}

export function sendFormImproveTool (
    api: any,
    data: any
) {
    const reportId = getReportId()
    const url = `/reports/${reportId}/feedback`

    return api.post(
        url,
        data
    )
}

export function sendUserComments (
    api: any,
    comment: string
) {
    const reportId = getReportId()
    const url = `/reports/${reportId}/user-comments`
    return api.post(
        url,
        {
            comment,
        }
    )
}

export function getReportById (
    api: any
) {
    const reportId = getReportId()
    const url = `/reports/${reportId}`
    return api.get(url)
}
