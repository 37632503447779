<template>
    <f-modal
        :buttonCancelText="'Réinitialiser le rapport'"
        :buttonOkText="'Continuer le rapport'"
        :isDisplayCloseIcon="false"
        :isModalActive="isModalActive"
        :typeOkButton="'primary'"
        :isCanCancelButton="true"
        @nokClick="handleResetInstall()"
        @okClick="handleContinueInstall()"
        style="z-index:420;"
    >
        <template v-slot:header>
            <h1 class="title is-5">
                Un rapport est déjà en cours
            </h1>
        </template>

        <template v-slot:content>
            <div>
                Des données sont déjà présentes dans le rapport en cours.
                <div
                    v-if="dataUser !== null"
                    class="mt-2"
                >
                    Installateur actuel: <strong>{{ dataUser }} </strong>
                </div>
                <div
                    v-if="reportIsSet"
                    class="mt-2"
                >
                    Bornes:
                    <ul>
                        <li
                            v-for="device in (showAllDevices ? reportStore.report?.devices : reportStore.report?.devices.slice(0, 5))"
                            :key="device.id"
                        >
                            - <strong>{{ device.chargeboxid }}</strong>
                        </li>
                        <li
                            v-if="!showAllDevices && reportStore.report?.devices.length > 5"
                            @click="toggleDevicesList"
                            style="cursor: pointer"
                        >
                            ...
                        </li>
                    </ul>
                </div>
                <div class="mt-4">
                    <strong>
                        Souhaitez vous continuer ou réinitialiser le rapport ?
                    </strong>
                </div>
            </div>

        </template>
    </f-modal>

</template>

<script lang="ts" setup>
import {
    computed, ref,
} from 'vue'
import { useRouter, } from 'vue-router'

import FModal from '@/components/ui/FModal.vue'

import { getRouteNameFromStepId, } from '@/services/routerService'
import { resetAllStoresExpectedUser, } from '@/services/storeService'
import useReportStore from '@/stores/report'
import useStepsStore from '@/stores/steps'
import useUserStore from '@/stores/User'

interface ModalResetInstallProps {
    isModalActive: boolean,
}

withDefaults(defineProps<ModalResetInstallProps>(), {
    isModalActive: false,
})

const userStore = useUserStore()
const reportStore = useReportStore()
const router = useRouter()
const stepsStore = useStepsStore()
const showAllDevices = ref(false)

const toggleDevicesList = () => {
    showAllDevices.value = !showAllDevices.value
}

const dataUser = computed(() => {
    if (userStore.token !== null && userStore.email !== null) return `${userStore.firstname} ${userStore.lastname}, ${userStore.email}`

    return null
})

const reportIsSet = computed(() => reportStore.report !== null)

const handleContinueInstall = () => {
    userStore.isModalResetInstallActive = false
    const nameRoute = getRouteNameFromStepId(stepsStore.currentStepId)
    if (nameRoute === null) router.push('/')
    else router.push({ name: nameRoute, })
}

const handleResetInstall = () => {
    userStore.isModalResetInstallActive = false
    userStore.isWorkshop = false
    resetAllStoresExpectedUser()
    router.push('/test-mode-selection')
}

defineEmits(['close', 'okClick'])
</script>
