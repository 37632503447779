import { defineStore, } from 'pinia'
import { ref, } from 'vue'

import type { Location, } from '@/types/report/Location.d'

const useReportStore = defineStore('report', () => {
    const report = ref<Location | null>(null)

    const $reset = () => {
        report.value = null
    }

    return { report, $reset, }
})

export default useReportStore
