<template>
    <f-modal
        :buttonCancelText="'Annuler'"
        :buttonOkText="'Envoyer'"
        :isDisplayCloseIcon="false"
        :isModalActive="isModalActive"
        :isOkButtonDisabled="isOkButtonDisabled"
        :typeOkButton="'primary'"
        :isLoading="isLoading"
        @close="$emit('close')"
        @nokClick="$emit('close')"
        @okClick="handleSendComment()"
    >
        <template v-slot:header>
            <h1 class="title is-5">
                Signaler un problème
            </h1>
        </template>

        <template v-slot:content>
            <strong>
                Donnez-nous le plus de détails possibles sur le problème que vous rencontrez.
            </strong>

            <o-input
                data-testid="signal-problem-input"
                v-model="inputValue"
                placeholder="Exemple: Je ne parviens pas à terminer les tests de la station car ..."
                type="textarea"
            />
        </template>
    </f-modal>

</template>

<script lang="ts" setup>
import { ref, watch, } from 'vue'

import { useApi, } from '@/config/api'

import FModal from '@/components/ui/FModal.vue'

import { sendUserComments, } from '@/api/api'

const isOkButtonDisabled = ref(true)
const inputValue = ref('')
const api = useApi()
const isLoading = ref(false)

interface ModalProps {
    isModalActive: boolean
}

withDefaults(defineProps<ModalProps>(), {
    isModalActive: false,
})

const emit = defineEmits(['close', 'okClick'])

const handleSendComment = async () => {
    isLoading.value = true
    sendUserComments(api.value, inputValue.value)
        .then(() => {
            emit('close')
        })
        .finally(() => {
            isLoading.value = false
        })
}

watch(inputValue, (newValue) => {
    isOkButtonDisabled.value = newValue.length === 0
})
</script>
