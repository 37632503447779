import messages from '@intlify/unplugin-vue-i18n/messages'
import { createI18n, } from 'vue-i18n'
// eslint-disable-next-line import/no-unresolved

const i18n = createI18n({
    locale: 'fr-FR',
    messages,
})

export default i18n
