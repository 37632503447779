import { library, } from '@fortawesome/fontawesome-svg-core'
import {
    faAngleDown,
    faAngleLeft,
    faAngleRight,
    faAngleUp,
    faBars,
    faCaretDown,
    faCaretUp,
    faCheck,
    faHourglassHalf,
    faLifeRing,
    faMinus,
    faPlay,
    faSearch,
    faStop,
    faXmark,
    faExclamationCircle,
    faSpinner,
    faArrowDown,
    faArrowDownLong,
} from '@fortawesome/free-solid-svg-icons'
// internal icons

library.add(
    faCheck,
    faXmark,
    faBars,
    faSearch,
    faAngleUp,
    faAngleRight,
    faAngleLeft,
    faAngleDown,
    faCaretDown,
    faCaretUp,
    faLifeRing,
    faPlay,
    faStop,
    faMinus,
    faHourglassHalf,
    faExclamationCircle,
    faSpinner,
    faArrowDown,
    faArrowDownLong
)
