import { routes, } from '@/config/router'

export function getRouteNameFromStepId (
    stepId: number
) {
    let routeFinded: null | string = null
    routes.forEach((route: any) => {
        if (route.children) {
            route.children.forEach((childRoute: any) => {
                if (childRoute.meta?.stepId === stepId && routeFinded === null)
                    routeFinded = childRoute.name
            })
        } else if (route.meta?.stepId === stepId && routeFinded === null)
            routeFinded = route.name
    })
    return routeFinded
}
