<template>
    <div class="progressbar">
        <h1
            data-testid="sidebar-etape-title"
            class="title is-6 title-step"
            v-if="props.isExtended"
        >
            Etape
        </h1>
        <div
            data-testid="sidebar-list-steps-div"
            class="list-steps"
        >
            <div
                :data-testid="`div-step-${index}`"
                v-for="(step, index) in stepsStore.steps"
                :key="step.name"
                class="is-flex is-align-items-center"
                :class="{
                    'first-step': index === 0,
                    'cursor-pointer': stepIsClickable(step, index),
                    'cursor-not-allowed': !stepIsClickable(step, index),
                }"
                @click="handleClickStep(step, index)"
                :title="stepDisabledInWorkshopMode(step) ? 'Désactivé en mode atelier' : ''"
            >
                <item-progress-bar
                    :step="step"
                    :isExtended="props.isExtended"
                />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { useRouter, } from 'vue-router'

import ItemProgressBar from '@/components/sidebar/ItemProgressBar.vue'

import { getRouteNameFromStepId, } from '@/services/routerService'
import useStepsStore from '@/stores/steps'
import useUserStore from '@/stores/User'

const router = useRouter()
const stepsStore = useStepsStore()
const userStore = useUserStore()

interface StepProgressBarProps {
    // eslint-disable-next-line vue/no-unused-properties
    isExtended: boolean
}

const props = defineProps<StepProgressBarProps>()

const stepDisabledInWorkshopMode = (step: any) => userStore.isWorkshop && step.isDisabledInWorkshopMode

const stepIsClickable = (step: any, index: any) => {
    if (stepDisabledInWorkshopMode(step)) return false

    return stepsStore.indexMaxStep >= index
}

const handleClickStep = (step: any, index: number) => {
    if (stepIsClickable(step, index)) {
        const routeName = getRouteNameFromStepId(step.id)
        if (routeName !== null) router.push({ name: routeName, })
    }
}
</script>

<style scoped lang="scss">
.list-steps {
  list-style: none;
}

.list-steps > div {
  position: relative;
  margin-top: 15px;
}

.progressbar {
    margin-top: 70px;
}

.title-step {
    margin-bottom: -5px;
}

.cursor-pointer{
    cursor: pointer;
}

.cursor-not-allowed{
    cursor: not-allowed;
}
</style>
