import '@/assets/styles/main.scss'
import { FontAwesomeIcon, } from '@fortawesome/vue-fontawesome'
import Oruga from '@oruga-ui/oruga-next'
import { bulmaConfig, } from '@oruga-ui/theme-bulma'
import '@/config/icons'
// @ts-ignore
import * as Sentry from '@sentry/vue'
import { createHead, } from '@unhead/vue'
import { createPinia, } from 'pinia'
import { createPersistedState, } from 'pinia-plugin-persistedstate'
import { createApp, } from 'vue'
import Matomo from 'vue-matomo'
import VueReCaptcha from 'vue-recaptcha'

import api from '@/config/api'
import i18n from '@/config/i18n'
import router from '@/config/router'

import App from './App.vue'

const store = createPinia()
store.use(createPersistedState({ auto: true, }))
const app = createApp(App)

/**
 * Check that Sentry has all the necessary env variables to be initialized
 */
const isSentryEnvValid = import.meta.env.SENTRY_DSN?.length
    && import.meta.env.VITE_ENV?.length

if (isSentryEnvValid) {
    Sentry.init({
        app,
        dsn: import.meta.env.SENTRY_DSN,
        environment: import.meta.env.VITE_ENV,
        integrations: [
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            }),
            new Sentry.Replay()
        ],
        tracesSampleRate: 0.05,
        replaysSessionSampleRate: 0.05,
        replaysOnErrorSampleRate: 0.5,
    })
} else
    console.warn('Crash reporting unavailable.')

app.component('vue-fontawesome', FontAwesomeIcon)

app.use(store)
app.use(router)
app.use(Oruga, {
    ...bulmaConfig,
    notification: {
        ...bulmaConfig.notification,
        duration: 6000,
    },
    iconComponent: 'vue-fontawesome',
    iconPack: 'fas',
})
app.use(i18n)
app.use(api)
app.use(Matomo, {
    host: 'https://analytics.freshmile.com',
    siteId: 6,
    router,
    enableLinkTracking: false,
    trackInitialView: false,
    disableCookies: true,
})
app.use(createHead())
app.use(VueReCaptcha, {
    v3SiteKey: import.meta.env.VITE_RECAPTCHA_SITE_KEY,
})

app.mount('#app')
